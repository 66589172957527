<template>
  <div class="app-layout">
    <!-- Fixed Header -->
    <header class="fixed-header">
      <div class="header-content">
        <button class="menu-toggle" @click="toggleMenu">
          <span :class="['menu-icon', { 'open': isMenuOpen }]"></span>
        </button>
        <router-link to="/" class="brand">Ring Size Calculator</router-link>
      </div>
    </header>

    <!-- Sidebar/Navigation -->
    <aside :class="['sidebar', { 'expanded': isMenuOpen }]">
      <nav class="nav-menu">
        <ul>
          <li v-for="(link, index) in navLinks" :key="index">
            <router-link
              v-if="!link.isAmazon"
              :to="link.path"
              class="nav-link"
              @click="handleNavClick"
              active-class="active">
              {{ link.text }}
            </router-link>
            <a
              v-else
              :href="link.path"
              class="nav-link amazon-link"
              target="_blank"
              @click="handleNavClick">
              {{ link.text }}
            </a>
          </li>
        </ul>
      </nav>
    </aside>

    <!-- Main Content -->
    <main :class="['main-content', { 'sidebar-expanded': isMenuOpen }]">
      <router-view />
    </main>
  </div>
</template>

<script>
export default {
  name: 'AppHeader',
  data() {
    return {
      isMenuOpen: false,
      navLinks: [
        { path: '/', text: 'Home' },
        { path: '/string-method', text: 'String Method' },
        { path: '/ring-method', text: 'Ring Method' },
        { path: '/size-converter', text: 'Size Converter' },
        { path: '/size-chart', text: 'Size Chart' },
        { path: '/ring-circles', text: 'Ring Circles' },
        { path: '/paper-ring-sizer', text: 'Paper Ring Sizer' }
      ]
    }
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
    },
    handleNavClick() {
      if (window.innerWidth <= 768) {
        this.isMenuOpen = false
      }
    }
  }
}
</script>

<style scoped>
.app-layout {
  min-height: 100vh;
  position: relative;
}

.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1000;
}

.header-content {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 1rem;
}

.brand {
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
  text-decoration: none;
  margin-left: 1rem;
}

.menu-toggle {
  width: 40px;
  height: 40px;
  border: none;
  background: transparent;
  cursor: pointer;
  position: relative;
  padding: 0;
}

.menu-icon {
  position: relative;
  display: block;
  width: 24px;
  height: 2px;
  background: #333;
  margin: auto;
  transition: 0.3s;
}

.menu-icon::before,
.menu-icon::after {
  content: '';
  position: absolute;
  width: 24px;
  height: 2px;
  background: #333;
  transition: 0.3s;
}

.menu-icon::before { top: -8px; }
.menu-icon::after { bottom: -8px; }

.menu-icon.open {
  background: transparent;
}

.menu-icon.open::before {
  transform: rotate(45deg);
  top: 0;
}

.menu-icon.open::after {
  transform: rotate(-45deg);
  bottom: 0;
}

.sidebar {
  position: fixed;
  top: 60px;
  left: 0;
  bottom: 0;
  width: 250px;
  background: #f8f9fa;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  z-index: 900;
}

.sidebar.expanded {
  transform: translateX(0);
}

.nav-menu {
  padding: 1rem 0;
}

.nav-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-link {
  display: block;
  padding: 0.75rem 1.5rem;
  color: #333;
  text-decoration: none;
  transition: 0.2s;
}

.nav-link:hover {
  background: #e9ecef;
}

.nav-link.active {
  background: #007bff;
  color: #fff;
}

.amazon-link {
  color: red;
  font-weight: bold;
}

.amazon-link:hover {
  text-decoration: underline;
}

.main-content {
  padding: 80px 20px 20px;
  transition: 0.3s;
}

@media (min-width: 769px) {
  .sidebar {
    transform: translateX(0);
  }

  .main-content {
    margin-left: 250px;
  }

  .sidebar.expanded {
    transform: translateX(-100%);
  }

  .main-content.sidebar-expanded {
    margin-left: 0;
  }
}
</style>
